import { STORAGE_PREFIX } from '@ridi-web/common';
import { Static as RStatic } from 'runtypes';

import { isBrowser } from '@/utils/isBrowser';

import { RIDIStorageKey, RIDIStorageTypes, StorageType } from './types';

const getStorage = (storageType: StorageType) =>
  storageType === 'session' ? window.sessionStorage : window.localStorage;

export const getStorageItemRaw = (storageType: StorageType, key: RIDIStorageKey): string | null =>
  isBrowser() ? getStorage(storageType).getItem(`${STORAGE_PREFIX}${key}`) : null;

export const setStorageItemRaw = (storageType: StorageType, key: RIDIStorageKey, value: string): void => {
  if (isBrowser()) {
    getStorage(storageType).setItem(`${STORAGE_PREFIX}${key}`, value);
  }
};

export const removeStorageItem = (storageType: StorageType, key: RIDIStorageKey): void => {
  if (isBrowser()) {
    getStorage(storageType).removeItem(`${STORAGE_PREFIX}${key}`);
  }
};

export const getStorageItemJSON = <K extends RIDIStorageKey>(
  storageType: StorageType,
  key: K,
): RStatic<(typeof RIDIStorageTypes)[K]> | null => {
  try {
    const rawValue = getStorageItemRaw(storageType, key);
    if (rawValue === null) {
      return null;
    }

    const validated = RIDIStorageTypes[key].validate(JSON.parse(rawValue));
    if (validated.success) {
      return validated.value;
    }

    return null;
  } catch {
    return null;
  }
};

export const setStorageItemJSON = <K extends RIDIStorageKey>(
  storageType: StorageType,
  key: K,
  value: RStatic<(typeof RIDIStorageTypes)[K]>,
): void => setStorageItemRaw(storageType, key, JSON.stringify(value));

export type { StorageType } from './types';
export { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from './types';
