import { config } from '@/config';

import { GetService, isInternalServerToServerRequest, makeServiceMethod } from '../baseService';

const mainDomain: Record<string, string | undefined> = {
  local: process.env.NEXT_PUBLIC_MAIN_DOMAIN,
  development: `https://${config.domain}`,
  stage: process.env.NEXT_PUBLIC_MAIN_DOMAIN,
  production: process.env.NEXT_PUBLIC_MAIN_DOMAIN,
  test: process.env.NEXT_PUBLIC_MAIN_DOMAIN,
};

export const getService: GetService = ({ path = '/apps/account' } = {}) => ({
  host: isInternalServerToServerRequest() ? process.env.SERVER_SIDE_BACKENDS : mainDomain[config.runMode],
  routePrefix: path,
});

export const makeCallAPIMethod = makeServiceMethod(getService);
