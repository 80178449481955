import type { FeatureFlagReferralEventData } from '@ridi-web/common';

import { SearchComponentFlag } from '@/queries/search/useSearchComponentsQuery';

export type FeatureFlagDurationValue = {
  startAt: string;
  endAt: string;
};

export const FEATURE_FLAG_KEYS = [
  'deauth-watchdog',
  'web-shipping-management',
  'web-internal-backendsapi-request-20240710',
  'web-internal-books-backendsapi-request-20240813',
  'web-realusermonitoring-20231221',
  'booksfrontend-datadog-rum-target-users-20240223',
  'web-referral-event-20240819',
  'servicebackends-referral-event-20240820',
  'web-inapp-search-exception-20241017',
  'web-auto-recharge-balance-20241212',
  'web-validate-resourcepath-20241127',
  'web-views-configures',
  'web-internal-category-request-20241224',
  'web-change-fantasy-keyword-finder-title-241218',
  'servicebackends-search-components',
  'web-old-book-component-20250113',
  'web-sampling-vitals-event-20250117',
  'web-selection-query-validation-20250214',
  'servicebackends-exp-frequently-searched-books',
  'web-first-monopoly-20250304',
  'servicebackends-srp-ux-improvement-by-tab-2025-03-13',
  'web-improve-big-banner-gradient-20250318',
] as const;

export type FeatureFlagKey = (typeof FEATURE_FLAG_KEYS)[number];

export const OLD_BOOK_COMPONENT_USAGES = [
  'NewRelease',
  'BestSeller',
  'Category',
  'GenreHome',
  'SelectionCarousel',
  'GroupTab',
  'KeywordFinder',
  'OnePick',
  'Selection',
] as const;

export type OldBookComponentUsage = (typeof OLD_BOOK_COMPONENT_USAGES)[number];

/**
 * 일반적으로 growth book이 on/off형태의 boolean이기 때문에 사용편의를 위해 기본 타입 정하고
 * 그 외에는 특정타입 사용자가 직접 추가하도록함
 */
type NonBooleanFeatureFlagTypes = {
  'web-shipping-management': FeatureFlagDurationValue & { promotionUrl: string };
  'booksfrontend-datadog-rum-target-users-20240223': number[];
  'servicebackends-referral-event-20240820': FeatureFlagReferralEventData;
  'web-auto-recharge-balance-20241212': { isActive: false } | { isActive: true; type: 'A' | 'B' | 'C' };
  'web-views-configures': {
    allowedViewIds: number[];
  };
  'servicebackends-search-components': {
    landing: SearchComponentFlag[];
  };
  'web-old-book-component-20250113': OldBookComponentUsage[];
  'web-sampling-vitals-event-20250117': {
    samplingRate: number;
  };
  'servicebackends-exp-frequently-searched-books': 'A' | 'B' | 'C';
  'servicebackends-srp-ux-improvement-by-tab-2025-03-13': 'EXP' | 'CONTROL';
};

type FEATURE_FLAG_TYPES = {
  [K in keyof NonBooleanFeatureFlagTypes]: NonBooleanFeatureFlagTypes[K];
} & {
  [K in Exclude<FeatureFlagKey, keyof NonBooleanFeatureFlagTypes>]: boolean;
};

export type FeatureFlagValue<FlagKey extends FeatureFlagKey> = FEATURE_FLAG_TYPES[FlagKey];

export type FeatureFlags = {
  flagKey: FeatureFlagKey;
  flagValue: FeatureFlagValue<FeatureFlagKey>;
}[];
