import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Store } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { ErrorProps } from 'next/error';
import { useState } from 'react';

import { IconCopyright, IconLogoRIDI } from '@/assets/svgs/system';
import { HttpStatusCodes } from '@/base/constants/httpStatusCodes';
import { ServerRequest } from '@/base/interfaces/ServerRequest';
import { PageHead } from '@/components/common/PageHead';
import { State } from '@/features/store';
import { wrapper } from '@/pages/wrapper';
import { isServer } from '@/services/baseService';
import { InAppBridge } from '@/utils/inappBridge';
import sendException, { isReportToSentry } from '@/utils/sentry/sendException';

import * as styles from './_error.styles';

export interface ConnectedInitializeProps extends NextPageContext {
  store: Store;
  req: ServerRequest;
}

interface ErrorPageProps extends ErrorProps {
  title?: string;
  message?: string;
  error?: Error;
  isInApp: boolean;
}

interface ErrorParagraphProps {
  checked: boolean;
}

export const ErrorParagraph = styled.p<ErrorParagraphProps>`
  display: ${({ checked }) => {
    const display = checked ? 'block' : 'none';
    return display;
  }};
`;

export const renderErrorContent = (statusCode: number, title?: string, message?: string): ReactJSX.Element => {
  let fallbackTitle;
  let fallbackMessage;

  switch (statusCode) {
    case HttpStatusCodes.BadRequest:
      fallbackTitle = '잘못된 요청입니다.';
      fallbackMessage = '잠시 후 다시 시도해주세요.';
      break;
    case HttpStatusCodes.Unauthorized:
      fallbackTitle = '인증되지 않은 유저입니다.';
      fallbackMessage = '로그인 후 다시 시도해 주세요.';
      break;
    case HttpStatusCodes.NotFound:
      fallbackTitle = '요청하신 페이지가 없습니다.';
      fallbackMessage = '입력하신 주소를 확인해 주세요.';
      break;
    case HttpStatusCodes.InternalServerError:
    default:
      fallbackTitle = '오류가 발생했습니다.';
      fallbackMessage = '잠시 후 다시 시도해주세요.';
  }

  return (
    <>
      <strong css={styles.errorTextStyle}>{title ?? fallbackTitle}</strong>
      <p css={styles.errorDescriptionStyle}>{message ?? fallbackMessage}</p>
    </>
  );
};

const Error = ({ statusCode, title, error, message, isInApp }: ErrorPageProps): ReactJSX.Element => {
  const [checked, setChecked] = useState(false);
  const code = statusCode >= 400 ? String(statusCode) : '';

  return (
    <main css={styles.errorPageStyle}>
      <Global styles={styles.globalStyle} />
      <PageHead title="리디" disableTitleSuffix />
      <header css={styles.headerStyle}>
        {isInApp ? (
          <span css={styles.headerAnchorStyle}>
            <IconLogoRIDI css={styles.logoStyle} />
          </span>
        ) : (
          <a href="/" css={styles.headerAnchorStyle} aria-label="리디 홈으로">
            <IconLogoRIDI css={styles.logoStyle} />
          </a>
        )}
      </header>
      <section css={styles.wrapperStyle}>
        <h2 css={styles.errorCodeStyle} data-testid="code">
          {code}
        </h2>
        {renderErrorContent(statusCode, title, message)}
        <div css={styles.buttonWrapperStyle}>
          {isInApp ? (
            <button css={styles.backButtonStyle} onClick={() => InAppBridge.control.close()} type="button">
              닫기
            </button>
          ) : (
            <>
              <button css={styles.backButtonStyle} onClick={() => window.history.back()} type="button">
                이전페이지
              </button>
              <button css={styles.homeButtonStyle} type="button">
                <a href="/">홈으로</a>
              </button>
            </>
          )}
        </div>

        {process.env.NODE_ENV !== 'production' && statusCode >= 500 && (
          <div css={styles.detailWrapperStyle}>
            <input
              type="checkbox"
              id="detail"
              onClick={() => setChecked(!checked)}
              css={styles.detailInputStyle}
              data-testid="detail"
            />
            <label htmlFor="detail" css={styles.detailLabelStyle}>
              자세히 보기
            </label>
            <ErrorParagraph checked={checked}>{error && error.toString()}</ErrorParagraph>
          </div>
        )}
      </section>
      <footer css={styles.footerStyle}>
        <IconCopyright css={styles.footerCopyrightIconStyle} />
        <span css={styles.footerCopyrightTextStyle}>RIDI Corp.</span>
      </footer>
    </main>
  );
};

const runMode = process.env.RUN_MODE;

Error.getInitialProps = wrapper.getInitialPageProps(store => async appContext => {
  const { req, res, err } = appContext;
  let isInApp = false;

  if (isServer()) {
    isInApp = !!(req as ServerRequest)?.Variables?.app;
  } else {
    isInApp = !!(store.getState() as State).global?.variables?.variables?.app;
  }

  if (err) {
    const statusCode = err.statusCode || res?.statusCode;

    if (typeof statusCode === 'undefined' || (runMode === 'production' && isReportToSentry(statusCode))) {
      sendException(err, { level: 'error' }, appContext);
    }

    return { statusCode: statusCode || HttpStatusCodes.InternalServerError, error: err, isInApp };
  }

  return { statusCode: HttpStatusCodes.NotFound, isInApp };
});

export default Error;
