import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import { RAuthor } from '@/base/interfaces/Author';
import type { Camelize } from '@/utils/namingConvention';

export const RBackendsBookAuthor = RAuthor.omit('id').extend({ author_id: RNumber });
export type BackendsBookAuthor = RStatic<typeof RBackendsBookAuthor>;

export const RBackendsBookCover = RRecord({
  small: RString,
  large: RString,
  xxlarge: RString,
});
export type BackendsBookCover = RStatic<typeof RBackendsBookCover>;

export const RBackendsBookOriginalCoverSize = RRecord({
  width: RNumber,
  height: RNumber,
});
export type BackendsBookOriginalCoverSize = RStatic<typeof RBackendsBookOriginalCoverSize>;

export const RBackendsBookCategory = RRecord({
  category_id: RNumber,
  name: RString,
  genre: RString,
  parent_id: RNumber,
  priority: RNumber.nullable(),
  use_series: RUnion(RLiteral('Y'), RLiteral('N')),
});
export type BackendsBookCategory = RStatic<typeof RBackendsBookCategory>;

export const RBackendsBookIntroduction = RRecord({
  romance_guide: RString.nullable(),
  sequence_guide: RString.nullable(),
  related_serial_info: RString.nullable(),
  trailer_video: RString.nullable(),
  trailer_image: RString.nullable(),
  table_of_contents: RString.nullable(),
  description: RString.nullable(),
  publisher_review: RString.nullable(),
  author_bio: RString.nullable(),
});
export type BackendsBookIntroduction = RStatic<typeof RBackendsBookIntroduction>;

export const RBackendsBookFileFormat = RUnion(RLiteral('pdf'), RLiteral('epub'), RLiteral('bom'), RLiteral('webtoon'));
export type BackendsBookFileFormat = RStatic<typeof RBackendsBookFileFormat>;

export const RBackendsBookFile = RRecord({
  size: RNumber,
  comic: RBoolean.nullable(),
  webtoon: RBoolean.nullable(),
  format: RBackendsBookFileFormat.nullable(),
  page_count: RNumber.nullable(),
  character_count: RNumber.nullable(),
});
export type BackendsBookFile = RStatic<typeof RBackendsBookFile>;

export const RBackendsBookPrice = RRecord({
  paperback_full_price: RNumber.optional(),
  full_price: RNumber,
  sale_price: RNumber,
});
export type BackendsBookPrice = RStatic<typeof RBackendsBookPrice>;

export const RBackendsBookPointback = RRecord({
  amount: RNumber,
  duration_days: RNumber,
});
export type BackendsBookPointback = RStatic<typeof RBackendsBookPointback>;

export const RBackendsBookRating = RRecord({
  rating: RNumber,
  count: RNumber,
});
export type BackendsBookRating = RStatic<typeof RBackendsBookRating>;

export const RBackendsBookPublisher = RRecord({
  publisher_id: RNumber,
  name: RString,
});
export type BackendsBookPublisher = RStatic<typeof RBackendsBookPublisher>;

export const RBackendsBookSet = RRecord({
  set_id: RString,
  title: RString,
  total: RNumber,
});
export type BackendsBookSet = RStatic<typeof RBackendsBookSet>;

export const RBackendsBookScheduledFree = RRecord({
  active: RBoolean,
  remain_episodes_count: RNumber,
});
export type BackendsBookScheduledFree = RStatic<typeof RBackendsBookScheduledFree>;

export const RBackendsBookStyleLayout = RUnion(
  RLiteral('genre-ebook'),
  RLiteral('webtoon-webnovel'),
  RLiteral('default-ebook'),
);
export type BackendsBookStyleLayout = RStatic<typeof RBackendsBookStyleLayout>;

export const RBackendsBookStyle = RRecord({
  layout: RBackendsBookStyleLayout,
});
export type BackendsBookStyle = RStatic<typeof RBackendsBookStyle>;

export const RBackendsSerialHeaderImage = RRecord({
  object: RString.nullable(),
  overlay: RString.nullable(),
  background: RString.nullable(),
  merged: RString.nullable(),
});
export type BackendsSerialHeaderImage = RStatic<typeof RBackendsSerialHeaderImage>;

export const RBackendsSerialPublishingSchedule = RRecord({
  periodical: RBoolean,
});
export type BackendsSerialPublishingSchedule = RStatic<typeof RBackendsSerialPublishingSchedule>;

export const RBackendsSerialWaitForFree = RRecord({
  interval_hours: RNumber,
  rental_days: RNumber,
  last_restricted_episodes: RNumber,
  next_available_date: RString.nullable(),
  opening_date: RString,
  closing_date: RString,
});
export type BackendsSerialWaitForFree = RStatic<typeof RBackendsSerialWaitForFree>;

export const RBackendsSerialPriceRange = RRecord({
  max_price: RNumber,
  min_price: RNumber,
  min_price_non_zero: RNumber,
});
export type BackendsSerialPriceRange = RStatic<typeof RBackendsSerialPriceRange>;

export const RBackendsSerialSellingPrice = RRecord({
  full_price: RNumber,
  sale_price: RNumber,
  days: RNumber.nullable().optional(),
  discount_rate: RNumber.nullable().optional(),
  max_discount_rate: RNumber.nullable().optional(),
  min_discount_rate: RNumber.nullable().optional(),
});
export type BackendsSerialSellingPrice = RStatic<typeof RBackendsSerialSellingPrice>;

export const RBackendsSerial = RRecord({
  serial_id: RString,
  title: RString,
  cover: RBackendsBookCover,
  original_cover_size: RBackendsBookOriginalCoverSize.nullable(),
  header_image: RBackendsSerialHeaderImage.nullable(),
  episode: RNumber.nullable(),
  total: RNumber,
  completion: RBoolean,
  publishing_schedule: RBackendsSerialPublishingSchedule.nullable(),
  wait_for_free: RBackendsSerialWaitForFree.nullable(),
  free: RRecord({ purchase: RNumber, rental: RNumber }),
  unit: RString.nullable(),
  purchase: RBackendsSerialPriceRange.nullable(),
  purchase_all: RBackendsSerialSellingPrice.nullable(),
  rental: RBackendsSerialPriceRange.nullable(),
  rental_all: RBackendsSerialSellingPrice.nullable(),
  last_opened_episode_date: RString.nullable(),
  scheduled_free: RBackendsBookScheduledFree.nullable(),
  is_new_episode_pre_exclusive: RBoolean,
  is_entire_series_pre_exclusive: RBoolean,
});
export type BackendsSerial = RStatic<typeof RBackendsSerial>;

export const RBackendsBook = RRecord({
  book_id: RString,
  title: RString,
  web_title: RString.nullable(),
  cover: RBackendsBookCover,
  original_cover_size: RBackendsBookOriginalCoverSize.nullable(),
  thumbnail: RString.nullable(),
  authors: RArray(RBackendsBookAuthor),
  categories: RArray(RBackendsBookCategory),
  introduction: RBackendsBookIntroduction.nullable(),
  isbn: RString.nullable(),
  adults_only: RBoolean,
  open: RBoolean,
  publication_date: RString,
  file: RBackendsBookFile,
  serial: RBackendsSerial.nullable(),
  purchase: RBackendsBookPrice.nullable(),
  rental: RBackendsBookPrice.extend({
    days: RNumber,
    pointback: RBackendsBookPointback.nullable(),
  }).nullable(),
  is_original: RBoolean,
  is_only: RBoolean,
  trial: RBoolean,
  style: RBackendsBookStyle.nullable(),
  ratings: RArray(RBackendsBookRating),
  publisher: RBackendsBookPublisher,
  set: RBackendsBookSet.nullable(),
  ridiselect: RBoolean,
  scheduled_free: RBackendsBookScheduledFree.nullable(),
  is_pre_exclusive: RBoolean,
});
export type BackendsBook = RStatic<typeof RBackendsBook>;
export type CamelizedBackendsBook = Camelize<BackendsBook>;
