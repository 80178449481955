import { checkoutRefreshAction, checkoutRefreshStartAction } from '../../checkout/checkoutSlice';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const autoChargeRidicashStartTransitionAction = createTransitionThunk(
  'autoChargeRidicashStartTransition',
  CheckoutStateTypes.CHECKOUT,
  (_previousState, _args, { dispatch }) => {
    dispatch(checkoutRefreshStartAction());

    return { state: CheckoutStateTypes.CHECKOUT_AUTO_CHARGE_RIDICASH };
  },
);

export const autoChargeRidicashFinishTransitionAction = createTransitionThunk(
  'autoChargeRidicashFinishTransition',
  CheckoutStateTypes.CHECKOUT_AUTO_CHARGE_RIDICASH,
  (_previousState, _args, { dispatch }) => {
    dispatch(checkoutRefreshAction({}));

    return { state: CheckoutStateTypes.CHECKOUT };
  },
);
