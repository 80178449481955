import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Variables } from '@/base/interfaces/Variables';
import type { GlobalState } from '@/features/global';
import { hydrateAction } from '@/features/hydrate';
import type { State } from '@/features/store';
import { debug } from '@/utils/debug';

import { hydrateAuthVariablesAction } from '../actions';

const log = debug('variablesSlice');

export interface VariablesState {
  variables: Variables | null;
}

export const variablesSelector = createSelector(
  (state: State) => state.global,
  (state: GlobalState) => state.variables,
);

const isDeviceTypeSelector = (deviceType: Variables['deviceType']) =>
  createSelector(variablesSelector, (state: VariablesState) => state.variables?.deviceType === deviceType);

export const isDesktopSelector = isDeviceTypeSelector('desktop');
export const isMobileSelector = isDeviceTypeSelector('mobile');
export const isTabletSelector = isDeviceTypeSelector('tablet');
export const isPaperSelector = isDeviceTypeSelector('paper');
export const isRidiAppSelector = createSelector(variablesSelector, (state: VariablesState) => !!state.variables?.app);
export const isRidiAppIOSSelector = createSelector(
  variablesSelector,
  (state: VariablesState) =>
    (state.variables?.app && state.variables?.operatingSystem === 'ios') ||
    ((state.variables?.app?.type ?? '') === 'tablet' && state.variables?.operatingSystem === 'macos'),
);
export const isOnepunSelector = createSelector(
  variablesSelector,
  (state: VariablesState) =>
    state.variables?.app?.type === 'mobile_onepun' || state.variables?.app?.type === 'tablet_onepun',
);

const initialState: VariablesState = {
  variables: null,
};

export const variablesSlice = createSlice({
  name: 'global/variables',
  initialState,
  reducers: {
    setVariablesAction: (state: VariablesState, action: PayloadAction<Variables>) => {
      log('setVariables', action);
      state.variables = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(hydrateAction, (state, action) => {
      if (action.payload.global.variables.variables !== null) {
        return {
          variables: {
            ...state.variables,
            ...action.payload.global.variables.variables,
          },
        };
      }

      return state;
    });

    builder.addCase(hydrateAuthVariablesAction.fulfilled, (state, action) => {
      state.variables = action.payload.variables;
    });
  },
});

export const { setVariablesAction } = variablesSlice.actions;
export type VariablesReducerType = ReturnType<typeof variablesSlice.reducer>;
