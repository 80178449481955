import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import type { ServerRequest } from '@/base/interfaces/ServerRequest';

import type { RequestConfig as OriginRequestConfig, RequestFailure, RequestResult, RequestSuccess } from '.';

const getBaseHost = (req?: ServerRequest): string | null => {
  if (req?.hostname) {
    return req?.hostname;
  }

  if (typeof window !== 'undefined') {
    return window.location.hostname;
  }

  return null;
};

export type RequestConfig = Omit<OriginRequestConfig, 'url'> & {
  host: string;
  pathname: string;
  retryAuth?: boolean;
};

const axiosInstance = axios.create();
export const requestWithRefreshAuth = async <E, T>(
  reqConfig: RequestConfig,
  req?: ServerRequest,
): Promise<RequestResult<E, T>> => {
  const baseHost = getBaseHost(req);

  const host = reqConfig.host || (baseHost ? `https://${baseHost}` : process.env.NEXT_PUBLIC_BOOKS_BACKEND_API);

  const url = new URL(reqConfig.pathname, host).toString();

  try {
    const result = await axiosInstance.request<T>({
      url: url.toString(),
      withCredentials: true,
      ...reqConfig,
    });
    return [undefined, result] as RequestSuccess<T>;
  } catch (err) {
    return [err, undefined] as RequestFailure<E>;
  }
};

axiosInstance.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    const { config: axiosConfig, response } = error;
    if (!response || response.status !== 401) {
      throw error;
    }

    if (axiosConfig && (axiosConfig as RequestConfig).retryAuth === false) {
      throw error;
    }

    try {
      const [err] = await requestWithRefreshAuth({
        host: process.env.NEXT_PUBLIC_ACCOUNT_API,
        pathname: '/ridi/token',
        method: 'POST',
        retryAuth: false,
      });

      if (err) {
        // Catched below
        throw error;
      }
    } catch {
      throw error;
    }

    return axiosInstance({
      ...axiosConfig,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: { ...axiosConfig?.headers },
      retryAuth: false,
    } as AxiosRequestConfig);
  },
);

export const isFailure = <E, T>(result: RequestResult<E, T>): result is RequestFailure<E> => !!result[0];
