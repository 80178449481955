import { captureException, Scope, withScope } from '@sentry/nextjs';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { NextPageContext } from 'next';

import { BaseError } from '@/base/errors/BaseError';

import { ExceptionOption } from './types';

const axiosErrorHandler = (scope: Scope, error: AxiosError) => {
  scope.setExtra('axios.config.url', error.config.url);
  scope.setExtra('axios.response.data', JSON.stringify(error.response?.data ?? {}));
  scope.setExtra('axios.response.statusCode', error.response?.status);

  scope.setTag('axios.response.statusCode', error.response?.status.toString() ?? 'UNKNOWN');
  scope.setTag('axios.config.url', error.config.url ?? 'UNKNOWN');

  scope.setFingerprint([error.config.url ?? '', error.response?.status.toString() ?? '', error.message]);
};

const pageContextHandler = (scope: Scope, ctx: NextPageContext) => {
  const { req, res, err, asPath, query } = ctx;
  scope.setExtra('nextjs.error', JSON.stringify(err));
  scope.setExtra('nextjs.query', query);

  if (req && res) {
    scope.setExtra('nextjs.response.statusCode', res.statusCode);
  }

  scope.setTag('isServer', String(Boolean(req)));
  scope.setTag('path', asPath ?? 'undefined');
};

const baseErrorHandler = (scope: Scope, error: BaseError) => {
  if (error.extra) {
    scope.setExtras(error.extra);
  }
};

const sendException = (
  error: AxiosError | Error,
  options: ExceptionOption,
  ctx: NextPageContext | null = null,
  scopeBuilder: ((scope: Scope) => void) | null = null,
): void => {
  withScope(scope => {
    scope.setLevel(options.level);

    if (options.extra) {
      scope.setExtras(options.extra);
    }

    if (options.tags) {
      scope.setTags(options.tags);
    }

    if (axios.isAxiosError(error)) {
      axiosErrorHandler(scope, error);
    }

    if (error instanceof BaseError) {
      baseErrorHandler(scope, error);
    }

    if (ctx) {
      pageContextHandler(scope, ctx);
    }

    if (scopeBuilder) {
      scopeBuilder(scope);
    }

    captureException(error);
  });
};

// 401 books-frontend 에서는 인증성공이나 API 에서는 인증 실패
// 그외 500대 에러에 대해 sentry 에러 로그 전송
export const isReportToSentry = (statusCode: number) => statusCode === 401 || statusCode >= 500;

// NOTE(gwak): 정상적인 구글/애플 소셜 회원가입 플로우 상에서 리디에 연동되지 않은 계정으로 소셜 로그인 진행 시
// 401 응답이 반환되기 때문에 401 응답은 오류로 간주하지 얺움
export const isServerErrorCode = (statusCode: number) => statusCode >= 500;

export default sendException;
