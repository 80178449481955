import { useEffect as C } from "react";
const l = "referralCode", I = "ridi-referral-event", Q = "ridi_app_theme", q = "ridi_nav", X = "ridi_auth", J = "adult_exclude", Z = "adult_include", ee = "login_time", te = "deauth_watchdog_servertoken", re = "use_grid", ne = "from_account_modify", ie = "fingerprint", oe = "add_pwa_requested", ce = "review-tab", ae = "FIG", se = "ridi-at", Ee = "ridi-rt", _e = "ridi-al", ue = "user_device_type", de = "ridi_app", le = "ridi_app_version", Ie = "ridi-ffid", fe = "PHPSESSID", Pe = "logout_time", Re = "__books__", pe = "server_metrics", he = "ruid", De = "ridi-referral-event", Ae = "app_instance_id", me = "app_ga_session_id", Se = "firebase_app_id", Te = "appsflyer_id", Ce = "inpay", ge = "_rdt_info", Oe = "iPod", ye = "iPhone", we = "iPad", ve = "Android", xe = "AndroidTab", Ye = "PC", Ne = "WindowsPhone", be = "WindowsCE", Le = "EtcMobileDevice", Ue = "PAPER", Ke = "PAPER Lite", ke = "PAPER PRO", Ve = "RIDIPAPER", Fe = "RIDIPAPER 4", g = "ridi", O = "ridiplaystore", y = "ridionestore", w = "ridiappstore", v = "saudi", Me = [
  g,
  O,
  y,
  w,
  v
];
/*! js-cookie v3.0.5 | MIT */
function d(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = arguments[r];
    for (var c in t)
      e[c] = t[c];
  }
  return e;
}
var x = {
  read: function(e) {
    return e[0] === '"' && (e = e.slice(1, -1)), e.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(e) {
    return encodeURIComponent(e).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function R(e, r) {
  function t(o, n, i) {
    if (!(typeof document > "u")) {
      i = d({}, r, i), typeof i.expires == "number" && (i.expires = new Date(Date.now() + i.expires * 864e5)), i.expires && (i.expires = i.expires.toUTCString()), o = encodeURIComponent(o).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var a = "";
      for (var s in i)
        i[s] && (a += "; " + s, i[s] !== !0 && (a += "=" + i[s].split(";")[0]));
      return document.cookie = o + "=" + e.write(n, o) + a;
    }
  }
  function c(o) {
    if (!(typeof document > "u" || arguments.length && !o)) {
      for (var n = document.cookie ? document.cookie.split("; ") : [], i = {}, a = 0; a < n.length; a++) {
        var s = n[a].split("="), u = s.slice(1).join("=");
        try {
          var _ = decodeURIComponent(s[0]);
          if (i[_] = e.read(u, _), o === _)
            break;
        } catch {
        }
      }
      return o ? i[o] : i;
    }
  }
  return Object.create(
    {
      set: t,
      get: c,
      remove: function(o, n) {
        t(
          o,
          "",
          d({}, n, {
            expires: -1
          })
        );
      },
      withAttributes: function(o) {
        return R(this.converter, d({}, this.attributes, o));
      },
      withConverter: function(o) {
        return R(d({}, this.converter, o), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(r) },
      converter: { value: Object.freeze(e) }
    }
  );
}
var f = R(x, { path: "/" });
const Y = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/, m = { secure: !0, domain: ".ridibooks.com", path: "/" };
function N(e) {
  if (!e)
    return !1;
  const r = new Date(e.eventStartAt).getTime(), t = new Date(e.eventEndAt).getTime();
  if (Number.isNaN(r) || Number.isNaN(t))
    return !1;
  const c = Date.now();
  return !(r <= c && c <= t);
}
function b(e, r) {
  if (e[l] || f.get(I)) {
    const { [l]: t, ...c } = e;
    f.remove(I, m);
    const o = new URLSearchParams(c).toString(), n = `${r.pathname}${o ? `?${o}` : ""}`;
    globalThis.history.replaceState({}, "", n);
  }
}
async function L({ referralEventData: e }) {
  if (!globalThis.location)
    return;
  const r = new URL(globalThis.location.href), t = Object.fromEntries(r.searchParams.entries());
  if (N(e)) {
    b(t, r);
    return;
  }
  if (!Y.test(globalThis.location.href))
    return;
  const c = t[l], o = f.get(I);
  if (c)
    f.set(I, c, m);
  else if (o) {
    const n = { ...t, [l]: o }, i = new URLSearchParams(n).toString(), a = `${r.pathname}?${i}`;
    globalThis.history.replaceState({}, "", a);
  }
}
const $e = (e, r) => {
  C(() => {
    L(e);
  }, [e.referralEventData, ...r]);
}, U = /* @__PURE__ */ new Map([
  ["author", "저자"],
  ["illustrator", "그림"],
  ["story_writer", "글"],
  ["original_author", "원작"],
  ["translator", "번역"],
  ["author_photo", "사진"],
  ["planner", "기획"],
  ["bibliographical_introduction", "해제"],
  ["compiler", "엮음"],
  ["commentator", "해설"],
  ["editor", "편집"],
  ["supervise", "감수"],
  ["performer", "연주자"],
  ["original_illustrator", "원화"]
]), h = Array.from(U.keys()).reduce(
  (e, r, t) => ({ ...e, [r]: t }),
  {}
), He = (e) => [...e].sort(
  (r, t) => (h[r.role] ?? 1 / 0) - (h[t.role] ?? 1 / 0)
);
var E = {};
Object.defineProperty(E, "__esModule", { value: !0 });
E.parse = H;
E.serialize = j;
const K = /^[\u0021-\u003A\u003C\u003E-\u007E]+$/, k = /^[\u0021-\u003A\u003C-\u007E]*$/, V = /^([.]?[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)([.][a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)*$/i, F = /^[\u0020-\u003A\u003D-\u007E]*$/, M = Object.prototype.toString, $ = /* @__PURE__ */ (() => {
  const e = function() {
  };
  return e.prototype = /* @__PURE__ */ Object.create(null), e;
})();
function H(e, r) {
  const t = new $(), c = e.length;
  if (c < 2)
    return t;
  const o = (r == null ? void 0 : r.decode) || z;
  let n = 0;
  do {
    const i = e.indexOf("=", n);
    if (i === -1)
      break;
    const a = e.indexOf(";", n), s = a === -1 ? c : a;
    if (i > s) {
      n = e.lastIndexOf(";", i - 1) + 1;
      continue;
    }
    const u = D(e, n, i), _ = A(e, i, u), P = e.slice(u, _);
    if (t[P] === void 0) {
      let p = D(e, i + 1, s), S = A(e, s, p);
      const T = o(e.slice(p, S));
      t[P] = T;
    }
    n = s + 1;
  } while (n < c);
  return t;
}
function D(e, r, t) {
  do {
    const c = e.charCodeAt(r);
    if (c !== 32 && c !== 9)
      return r;
  } while (++r < t);
  return t;
}
function A(e, r, t) {
  for (; r > t; ) {
    const c = e.charCodeAt(--r);
    if (c !== 32 && c !== 9)
      return r + 1;
  }
  return t;
}
function j(e, r, t) {
  const c = (t == null ? void 0 : t.encode) || encodeURIComponent;
  if (!K.test(e))
    throw new TypeError(`argument name is invalid: ${e}`);
  const o = c(r);
  if (!k.test(o))
    throw new TypeError(`argument val is invalid: ${r}`);
  let n = e + "=" + o;
  if (!t)
    return n;
  if (t.maxAge !== void 0) {
    if (!Number.isInteger(t.maxAge))
      throw new TypeError(`option maxAge is invalid: ${t.maxAge}`);
    n += "; Max-Age=" + t.maxAge;
  }
  if (t.domain) {
    if (!V.test(t.domain))
      throw new TypeError(`option domain is invalid: ${t.domain}`);
    n += "; Domain=" + t.domain;
  }
  if (t.path) {
    if (!F.test(t.path))
      throw new TypeError(`option path is invalid: ${t.path}`);
    n += "; Path=" + t.path;
  }
  if (t.expires) {
    if (!B(t.expires) || !Number.isFinite(t.expires.valueOf()))
      throw new TypeError(`option expires is invalid: ${t.expires}`);
    n += "; Expires=" + t.expires.toUTCString();
  }
  if (t.httpOnly && (n += "; HttpOnly"), t.secure && (n += "; Secure"), t.partitioned && (n += "; Partitioned"), t.priority)
    switch (typeof t.priority == "string" ? t.priority.toLowerCase() : void 0) {
      case "low":
        n += "; Priority=Low";
        break;
      case "medium":
        n += "; Priority=Medium";
        break;
      case "high":
        n += "; Priority=High";
        break;
      default:
        throw new TypeError(`option priority is invalid: ${t.priority}`);
    }
  if (t.sameSite)
    switch (typeof t.sameSite == "string" ? t.sameSite.toLowerCase() : t.sameSite) {
      case !0:
      case "strict":
        n += "; SameSite=Strict";
        break;
      case "lax":
        n += "; SameSite=Lax";
        break;
      case "none":
        n += "; SameSite=None";
        break;
      default:
        throw new TypeError(`option sameSite is invalid: ${t.sameSite}`);
    }
  return n;
}
function z(e) {
  if (e.indexOf("%") === -1)
    return e;
  try {
    return decodeURIComponent(e);
  } catch {
    return e;
  }
}
function B(e) {
  return M.call(e) === "[object Date]";
}
var G = /* @__PURE__ */ ((e) => (e.BUYER = "buyer", e.ALL = "all", e))(G || {});
const je = (e) => e ? E.parse(e) : null, ze = (e, r, t) => {
  if (r === void 0) {
    document.cookie = E.serialize(e, "", {
      ...t,
      expires: /* @__PURE__ */ new Date(0)
    });
    return;
  }
  document.cookie = E.serialize(e, r, t);
};
function Be(e) {
  return e != null;
}
const Ge = (e) => e.length <= 3 ? e.replace(/.$/, "*") : e.substring(0, 3) + e.substring(3).replace(/./g, "*"), We = (e, r) => {
  if (!r)
    return 0;
  const t = 1 - e / r;
  return Math.floor((t + Number.EPSILON) * 100);
};
export {
  se as ACCESS_TOKEN_KEY,
  oe as ADD_PWA_REQUESTED_KEY,
  J as ADULT_EXCLUDE_KEY,
  Z as ADULT_INCLUDE_KEY,
  _e as AUTO_LOGIN_KEY,
  Me as AVAILABLE_SCHEMES,
  te as DEAUTH_WATCHDOG_SERVER_TOKEN_KEY,
  ve as DEVICE_TYPE_ANDROID,
  xe as DEVICE_TYPE_ANDROID_TABLET,
  Le as DEVICE_TYPE_ETC_MOBILE,
  we as DEVICE_TYPE_IPAD,
  ye as DEVICE_TYPE_IPHONE,
  Oe as DEVICE_TYPE_IPOD,
  ue as DEVICE_TYPE_KEY,
  Ue as DEVICE_TYPE_PAPER,
  Ke as DEVICE_TYPE_PAPER_LITE,
  ke as DEVICE_TYPE_PAPER_PRO,
  Ye as DEVICE_TYPE_PC,
  Ve as DEVICE_TYPE_RIDIPAPER,
  Fe as DEVICE_TYPE_RIDIPAPER4,
  be as DEVICE_TYPE_WINDOWS_CE,
  Ne as DEVICE_TYPE_WINDOWS_PHONE,
  ie as FINGERPRINT_KEY,
  ne as FROM_ACCOUNT_MODIFY,
  Ce as INPAY,
  ee as LOGIN_TIME_KEY,
  Pe as LOGOUT_TIME_KEY,
  ae as NAMESPACE,
  fe as PHP_SESSION_KEY,
  ge as RDT_INFO,
  De as REFERRAL_CODE,
  I as REFERRAL_COOKIE_KEY,
  l as REFERRAL_QUERY_KEY,
  Ee as REFRESH_TOKEN_KEY,
  ce as REVIEW_TAB_KEY,
  Te as RIDI_APP_APPSFLYER_ID,
  Se as RIDI_APP_FIREBASE_APP_ID,
  me as RIDI_APP_GA_SESSION_ID,
  Ae as RIDI_APP_INSTANCE_ID,
  de as RIDI_APP_KEY,
  Q as RIDI_APP_THEME_KEY,
  le as RIDI_APP_VERSION_KEY,
  X as RIDI_AUTH_INFO_KEY,
  Ie as RIDI_FFID_KEY,
  q as RIDI_NAVIGATION_KEY,
  he as RUID,
  G as ReviewTab,
  g as SCHEME_RIDI,
  w as SCHEME_RIDIAPPSTORE,
  y as SCHEME_RIDIONESTORE,
  O as SCHEME_RIDIPLAYSTORE,
  v as SCHEME_SAUDI,
  pe as SERVER_METRICS,
  Re as STORAGE_PREFIX,
  re as USE_GRID_BOOK_LISTING,
  U as authorRoleTexts,
  We as getDiscountPercentage,
  je as getParsedCookie,
  Be as isNonNullable,
  Ge as maskingUserId,
  ze as setCookie,
  He as sortAuthors,
  L as syncReferralCode,
  $e as useSyncReferralCode
};
