import {
  Array as RArray,
  Literal as RLiteral,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import type { Camelize } from '@/utils/namingConvention';

export const RBackendsBadgeType = RUnion(
  RLiteral('Original'),
  RLiteral('WaitFree'),
  RLiteral('Up'),
  RLiteral('Only'),
  RLiteral('FreeSeries'),
  RLiteral('PartialFree'),
  RLiteral('Discount'),
  RLiteral('Select'),
  RLiteral('Rent'),
);
export type BackendsBadgeType = RStatic<typeof RBackendsBadgeType>;

export const RBackendsBadge = RRecord({
  badge_type: RBackendsBadgeType,
  badge_urls: RArray(RString),
  badge_text: RString,
  badge_background: RRecord({
    light: RString,
    dark: RString,
  }),
});
export type BackendsBadge = RStatic<typeof RBackendsBadge>;
export type CamelizedBackendsBadge = Camelize<BackendsBadge>;
