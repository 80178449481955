import { createAction } from '@reduxjs/toolkit';

import { CheckoutStates, CheckoutStateTypes, FailureState, FetchCheckoutContinuation } from '../types';
import { abortTransitionAction } from './abortTransition';
import { adultCheckTransitionAction } from './adultCheckTransition';
import {
  autoChargeRidicashFinishTransitionAction,
  autoChargeRidicashStartTransitionAction,
} from './autoChargeRidicashTransition';
import { chargeRidicashFinishTransitionAction, chargeRidicashStartTransitionAction } from './chargeRidicashTransition';
import { checkoutTransitionAction } from './checkoutTransition';
import {
  episodeFreeCouponsTransitionAction,
  episodeFreeCouponsUseTransitionAction,
} from './episodeFreeCouponsTransition';
import { fetchStartTransitionAction } from './fetchStartTransition';
import {
  freeCouponsFinalizeSuccessTransitionAction,
  freeCouponsTransitionAction,
  freeCouponsUseTransitionAction,
} from './freeCouponsTransition';
import { freePaymentTransitionAction } from './freePaymentTransition';
import { paymentRequestTransitionAction } from './paymentRequestTransition';

export {
  abortTransitionAction,
  adultCheckTransitionAction,
  chargeRidicashFinishTransitionAction,
  chargeRidicashStartTransitionAction,
  checkoutTransitionAction,
  episodeFreeCouponsTransitionAction,
  episodeFreeCouponsUseTransitionAction,
  fetchStartTransitionAction,
  freeCouponsFinalizeSuccessTransitionAction,
  freeCouponsTransitionAction,
  freeCouponsUseTransitionAction,
  freePaymentTransitionAction,
  paymentRequestTransitionAction,
};

export const adultCheckSuccessTransitionAction = createAction(
  'inapp/checkout/checkoutState/adultCheckSuccessTransitionAction',
  () => ({
    payload: { state: CheckoutStateTypes.ADULT_CHECK_SUCCESS } as CheckoutStates,
  }),
);

export const adultVerifyTransitionAction = createAction(
  'inapp/checkout/checkoutState/adultVerifyTransitionAction',
  () => ({
    payload: { state: CheckoutStateTypes.FAILURE_ADULT_VERIFY } as CheckoutStates,
  }),
);

export const failureTransitionAction = createAction(
  'inapp/checkout/checkoutState/failureTransitionAction',
  (reason: string | null = null, code?: FailureState['code']) => ({
    payload: { state: CheckoutStateTypes.FAILURE, reason, code } as CheckoutStates,
  }),
);

export const unauthorizedTransitionAction = createAction(
  'inapp/checkout/checkoutState/unauthorizedTransitionAction',
  () => ({
    payload: { state: CheckoutStateTypes.FAILURE_UNAUTHORIZED } as CheckoutStates,
  }),
);

export const waitCheckoutTransitionAction = createAction(
  'inapp/checkout/checkoutState/waitCheckoutTransitionAction',
  (continuation: FetchCheckoutContinuation) => ({
    payload: { state: CheckoutStateTypes.FETCH_CHECKOUT_WAITING, continuation } as CheckoutStates,
  }),
);

export const waitPaymentRouteBookTransitionAction = createAction(
  'inapp/checkout/checkoutState/waitPaymentRouteBookTransitionAction',
  () => ({
    payload: { state: CheckoutStateTypes.FETCH_PAYMENTROUTEBOOK_WAITING } as CheckoutStates,
  }),
);

export const waitPaymentRouteEpisodeTransitionAction = createAction(
  'inapp/checkout/checkoutState/waitPaymentRouteEpisodeTransitionAction',
  () => ({
    payload: { state: CheckoutStateTypes.FETCH_PAYMENTROUTEEPISODE_WAITING } as CheckoutStates,
  }),
);

export const thunkTypes = [
  abortTransitionAction,
  adultCheckTransitionAction,
  autoChargeRidicashStartTransitionAction,
  autoChargeRidicashFinishTransitionAction,
  chargeRidicashFinishTransitionAction,
  chargeRidicashStartTransitionAction,
  checkoutTransitionAction,
  episodeFreeCouponsTransitionAction,
  episodeFreeCouponsUseTransitionAction,
  fetchStartTransitionAction,
  freeCouponsFinalizeSuccessTransitionAction,
  freeCouponsTransitionAction,
  freeCouponsUseTransitionAction,
  freePaymentTransitionAction,
  paymentRequestTransitionAction,
];

export const actionTypes = [
  adultCheckSuccessTransitionAction,
  adultVerifyTransitionAction,
  failureTransitionAction,
  unauthorizedTransitionAction,
  waitCheckoutTransitionAction,
  waitPaymentRouteBookTransitionAction,
  waitPaymentRouteEpisodeTransitionAction,
];
