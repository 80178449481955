import { SCHEME_SAUDI } from '@ridi-web/common';

import type { Config } from '../types';

const config: Config = {
  runMode: 'test',
  allowedDomains: ['ridi.io'],
  domain: 'local.ridi.io',
  ridiComDomain: 'local.ridi.com',
  nodeCluster: {
    workerCount: 1,
  },

  endpoints: {
    webViewer: {
      default: 'https://view.dev.ridi.io',
    },
    ezwel: {
      default: 'https://openapi.dev.ezwel.com',
    },
  },

  thirdParties: {
    saudi: {
      clientId: 'faCXDXnluCaWNyD36x0vUgXsuOcWaF4jpyevmcAq',
      redirectUrl: `${SCHEME_SAUDI}://home`,
    },
    ezwel: {
      clientId: 'YovJDReQZrPCOtR3Rq9eNEiDDBylxLA4U1oNqznC',
      redirectUrl: 'https://next.local.ridi.io/account/connect/success',
    },
    naver: {
      clientId: '6b_8KwZ0qNwjpxUU8DZT',
    },
    kakao: {
      restApiKey: '83ca469073030adf67f3200be5483582',
      javaScriptKey: '2423c30c0b07128a386a793dcabb8642',
    },
    apple: {
      clientId: 'com.initialcoms.BOM.service.login',
    },
    google: {
      clientId: '78321583520-esfoie314n4cf4esm97tnd1a184cn6b4.apps.googleusercontent.com',
    },
  },
};

export default config;
