import { reconstate } from '@ridi-web/reconstate';

import type { FeatureFlagKey, FeatureFlags } from '@/utils/featureFlag';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

const useFeatureFlagProvider = ({ featureFlag = [] as FeatureFlags }: { featureFlag?: FeatureFlags }) => ({
  featureFlag,
  featureFlagValueByKey: <FlagKey extends FeatureFlagKey>(featureFlagKey: FlagKey) =>
    getFeatureFlagValueByKey({ featureFlag, featureFlagKey }),
});

const draft = reconstate(useFeatureFlagProvider);

export const useFeatureFlag = draft.add(({ featureFlag }) => featureFlag);

export const FeatureFlagProvider = draft.finalize();

export const useFeatureFlagValueByKey = <FlagKey extends FeatureFlagKey>(key: FlagKey) => {
  const featureFlag = useFeatureFlag();
  return getFeatureFlagValueByKey({ featureFlag, featureFlagKey: key });
};
