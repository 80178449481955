import {
  Array as RArray,
  Boolean as RBoolean,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Unknown as RUnknown,
} from 'runtypes';

export const RFeatureFlag = RArray(
  RRecord({
    flagKey: RString,
    flagValue: RUnknown,
  }),
);

export const RSingleFeatureFlag = RRecord({
  flagKey: RString,
  flagValue: RUnknown,
});

export const RFeatureFlagResponse = RRecord({
  success: RBoolean,
  message: RString.nullable(),
  data: RFeatureFlag,
});

export const RSingleFeatureFlagResponse = RRecord({
  success: RBoolean,
  message: RString.nullable(),
  data: RSingleFeatureFlag,
});

export type FeatureFlagResponse = RStatic<typeof RFeatureFlagResponse>;

export type SingleFeatureFlagResponse = RStatic<typeof RSingleFeatureFlagResponse>;
