import { SCHEME_SAUDI } from '@ridi-web/common';

import type { Config } from '../types';

const config: Config = {
  runMode: 'local',
  allowedDomains: ['ridi.io', 'localhost'],
  domain: 'next.local.ridi.io',
  ridiComDomain: 'next.local.ridi.com',
  /**
   * cluster cpu unit 수에 맞춰 할당해야합니다.
   * ecs, eks 간 코어 개수 차이, 하이퍼 쓰레딩 등등 node:cluster 권장인 `os.availableParallelism()`의 값이 다르기 때문에
   * 1vCPU(1024), 1000m = 1 worker를 기준으로 리소스 산정 고정.
   * prod, canary 외에는 1vCPU 고정.
   * eks: https://github.com/ridi/ridi-devops/blob/main/charts/books-frontend/values-prod.yaml#L8
   * ecs: https://github.com/ridi/ridi/blob/master/terraform/ridi-prod-books-frontend/taskdef.json#L233
   */
  nodeCluster: {
    workerCount: 1,
  },

  endpoints: {
    webViewer: {
      default: 'https://view.local.ridi.io',
    },
    ezwel: {
      default: 'https://openapi.dev.ezwel.com',
    },
  },

  thirdParties: {
    saudi: {
      clientId: 'faCXDXnluCaWNyD36x0vUgXsuOcWaF4jpyevmcAq',
      redirectUrl: `${SCHEME_SAUDI}://home`,
    },
    ezwel: {
      clientId: 'YovJDReQZrPCOtR3Rq9eNEiDDBylxLA4U1oNqznC',
      redirectUrl: 'https://dev.ridi.io/account/connect/success',
    },
    naver: {
      clientId: '6b_8KwZ0qNwjpxUU8DZT',
    },
    kakao: {
      restApiKey: '83ca469073030adf67f3200be5483582',
      javaScriptKey: '2423c30c0b07128a386a793dcabb8642',
    },
    apple: {
      clientId: 'com.initialcoms.BOM.service.login',
    },
    google: {
      clientId: '78321583520-esfoie314n4cf4esm97tnd1a184cn6b4.apps.googleusercontent.com',
    },
  },
};

export default config;
