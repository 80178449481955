import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

import { ZIndexLayer } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const errorPageStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const footerStyle = (theme: RIDITheme): SerializedStyles => css`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.grey400};
  font-size: 11px;
  line-height: 13px;
  padding-bottom: 20px;
`;

export const footerCopyrightIconStyle = css`
  margin-top: 1px;
  margin-right: 2px;
`;

export const footerCopyrightTextStyle = css`
  font-weight: 400;
  letter-spacing: -0.01em;
`;

const defaultButtonStyle = css`
  display: inline-block;
  width: 144px;
  padding-top: 15px;
  padding-bottom: 14px;
  margin: 0 5px;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  transition:
    background 0.2s,
    color 0.2s;
  outline: 0;
  box-sizing: border-box;
  border-radius: 50px;
`;

export const backButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${defaultButtonStyle}
  border: 1px solid ${theme.colors.grey300};
  background: ${theme.colors.white};
`;

export const homeButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${defaultButtonStyle}
  color: ${theme.colors.white};
  background: ${theme.colors.fillCta};
`;

export const detailWrapperStyle = css`
  margin-top: 50px;
  font-size: 10px;
  color: ${colors.slateGray20};
`;

export const detailInputStyle = css`
  display: none;
`;

export const detailLabelStyle = css`
  text-decoration: underline;
`;

export const headerStyle = (theme: RIDITheme): SerializedStyles => css`
  height: 73px;
  margin-bottom: -73px;
  border-bottom: 1px solid ${theme.colors.grey200};
  text-align: center;

  z-index: ${ZIndexLayer.LEVEL_HEADER};
`;

export const headerAnchorStyle = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 0 14px;
  padding-top: 24px;
  padding-bottom: 25px;
`;

export const logoStyle = css`
  font-size: 23px;
`;

export const wrapperStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const errorCodeStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 200px;
  font-weight: 200;
  line-height: 238.67px;
  letter-spacing: -0.01em;
  color: ${theme.colors.black};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 120px;
      line-height: 160px;
    `,
  )}
`;

export const errorTextStyle = (theme: RIDITheme): SerializedStyles => css`
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${theme.colors.grey600};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 20px;
    `,
  )}
`;

export const errorDescriptionStyle = (theme: RIDITheme): SerializedStyles => css`
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${theme.colors.grey500};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 14px;
    `,
  )}
`;

export const buttonWrapperStyle = css`
  margin-top: 40px;
`;

export const globalStyle = css`
  html,
  body,
  #__next {
    height: 100%;
  }
`;
