import { SCHEME_SAUDI } from '@ridi-web/common';

import type { Config } from '../types';

const config: Config = {
  runMode: 'production',
  allowedDomains: ['ridi.com', 'ridibooks.com'],
  domain: 'ridibooks.com',
  ridiComDomain: 'ridi.com',
  /**
   * cluster cpu unit 수에 맞춰 할당해야합니다.
   * ecs, eks 간 코어 개수 차이, 하이퍼 쓰레딩 등등 node:cluster 권장인 `os.availableParallelism()`의 값이 다르기 때문에
   * 1vCPU(1024), 1000m = 1 worker를 기준으로 리소스 산정 고정.
   * prod, canary 외에는 1vCPU 고정.
   * eks: https://github.com/ridi/ridi-devops/blob/main/charts/books-frontend/values-prod.yaml#L8
   * ecs: https://github.com/ridi/ridi/blob/master/terraform/ridi-prod-books-frontend/taskdef.json#L233
   */
  nodeCluster: {
    workerCount: 2,
  },

  endpoints: {
    webViewer: {
      default: 'https://view.ridibooks.com',
    },
    ezwel: {
      default: 'https://openapi.ezwel.com',
    },
  },

  thirdParties: {
    saudi: {
      clientId: 'eCLa1eeYHK8cZNAgjd8unhPAMd5jowPwN8wPPMse',
      redirectUrl: `${SCHEME_SAUDI}://home`,
    },
    ezwel: {
      clientId: 'J3751luvGnmMnuAEzhr5WFPzGMk4wA2LmyYtPnVA',
      redirectUrl: 'https://ridibooks.com/account/connect/success',
    },
    naver: {
      clientId: 'teTTFk7JhxPI5TxREu28',
    },
    kakao: {
      restApiKey: '52e4fb87d417ee59f68b95948a943a9e',
      javaScriptKey: '5fecd8f4eab7ae9108879d32b1cb7d14',
    },
    apple: {
      clientId: 'com.initialcoms.BOM.service.login',
    },
    google: {
      clientId: '78321583520-esfoie314n4cf4esm97tnd1a184cn6b4.apps.googleusercontent.com',
    },
  },
};

export default config;
